import Typography from "typography"
import { colors } from "./theme/colors"

const typography = new Typography({
  baseFontSize: 16,
  scaleRatio: 3.5,
  headerFontFamily: [
    "Raleway",
    "-apple-system",
    "BlinkMacSystemFont",
    "Segoe UI",
    "Oxygen-Sans",
    "Ubuntu",
    "Cantarell",
    "Helvetica Neue",
    "sans-serif",
  ],
  headerWeight: 600,
  headerColor: colors.code.secondary,
  bodyFontFamily: [
    "Raleway",
    "-apple-system",
    "BlinkMacSystemFont",
    "Segoe UI",
    "Oxygen-Sans",
    "Ubuntu",
    "Cantarell",
    "Helvetica Neue",
    "sans-serif",
  ],
  a: {
    fontFamily: [
      "Raleway",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Oxygen-Sans",
      "Ubuntu",
      "Cantarell",
      "Helvetica Neue",
      "sans-serif",
    ],
    headerWeight: 500,
  },
})

export const { scale, rhythm, options } = typography
export default typography
